<template>
  <div id="temporaryCharges">
    <el-button
      type="primary"
      size="mini"
      v-if="
        buttonList.includes('parkingLotList-detail-temporaryChargesTab-addBtn')
      "
      @click="addRule"
      >添加会员收费规则</el-button
    >
    <div class="ruleDetail-wrap">
      <div
        class="table-container"
        v-for="(item, index) of temporaryChargesList"
        :key="index"
      >
        <div class="table-title">
          <div>
            <span class="dot"></span>
            {{ item.baseForm.priceName }}
          </div>
          <div class="operation">
            <el-button
              type="primary"
              size="mini"
              v-if="
                buttonList.includes(
                  'parkingLotList-detail-temporaryChargesTab-calculation'
                )
              "
              @click="calculation(item.priceId)"
              >计算</el-button
            >
            <el-button
              type="primary"
              size="mini"
              v-if="
                buttonList.includes(
                  'parkingLotList-detail-temporaryChargesTab-delBtn'
                )
              "
              @click="deleteRule(item.priceId)"
              >删除</el-button
            >
            <el-button
              type="primary"
              size="mini"
              v-if="
                buttonList.includes(
                  'parkingLotList-detail-temporaryChargesTab-editBtn'
                )
              "
              @click="editRule(item)"
              >编辑</el-button
            >
          </div>
        </div>
        <div class="ruleDetail">
          <rule-preview
            :baseForm="item.baseForm"
            :chargeForm="item.chargeForm"
            :detailForm="item.detailForm"
            :priceType="item.priceType"
            :periodHours="item.baseForm.periodHours"
            :timeRuleForm="item.timeRuleForm"
          />
        </div>
      </div>
    </div>
    <charging-rules
      ref="chargingRules"
      @refreshData="getTemporaryChargesList"
    />
    <feeCalculation ref="feeCalculation" />
  </div>
</template>
<script>
export default {
  components: {
    rulePreview: () => import("@/components/chargingRules/rulePreview"),
    chargingRules: () => import("@/components/chargingRules/chargingRules.vue"),
    feeCalculation: () =>
      import("@/components/chargingRules/feeCalculation.vue"),
  },
  data() {
    return {
      temporaryChargesList: [],
    };
  },
  created() {
    this.getTemporaryChargesList();
  },
  methods: {
    calculation(priceId) {
      this.$refs.feeCalculation.priceId = priceId;
      this.$refs.feeCalculation.show = true;
    },
    async getTemporaryChargesList() {
      try {
        let res = await this.$http.get(
          `/price/temp/${this.$route.params.id}/list`
        );
        if (res.code === 0) {
          const list = [];
          for (let item of res.data) {
            const releDetail = await this.getRuleDetail(item.priceId);
            list.push(releDetail);
          }
          this.$set(this, "temporaryChargesList", list);
        } else {
          this.$message.error(res.msg);
        }
      } catch (err) {
        this.$message.error(err);
      }
    },
    getRuleDetail(priceId) {
      return new Promise(async (resolve, reject) => {
        try {
          let res = await this.$http.get(`/price/detail/${priceId}`);
          if (res.code === 0) {
            if (res.data.priceType === "组合收费") {
              console.log(res.data.priceDetailList);
              for (let item of res.data.priceDetailList) {
                item.timeEnd = String(item.timeEnd).padStart(4, "0");
                item.timeStart = String(item.timeStart).padStart(4, "0");
                for (let items of item.priceUnitList) {
                  items.id = Math.random();
                }
              }
              resolve({
                priceId: res.data.priceId,
                baseForm: {
                  priceName: res.data.priceName,
                  parkingLotId: res.data.parkingLotId,
                  priceVersion: res.data.priceVersion,
                  countWithArrival: res.data.countWithArrival,
                },
                chargeForm: {
                  freeDuration: res.data.freeDuration,
                  freeUnit: res.data.freeUnit,
                  freeIncluded: res.data.freeIncluded === 1,
                  highestCharge: res.data.highestCharge,
                  onceDuration: res.data.onceDuration,
                  onceCharge: res.data.onceCharge,
                  onceUnit: res.data.onceUnit,
                  priceComment: res.data.priceComment,
                  freeLeaveMinutes: res.data.freeLeaveMinutes,
                },
                detailForm: {
                  priceDetailList: res.data.priceDetailList,
                },
                priceType: res.data.priceType,
              });
            } else if (res.data.priceType === "按时长收费") {
              resolve({
                priceId: res.data.priceId,
                baseForm: {
                  parkingLotId: res.data.parkingLotId,
                  priceName: res.data.priceName,
                  priceType: res.data.priceType,
                  periodHours: res.data.periodHours,
                  priceVersion: res.data.priceVersion,
                },
                chargeForm: {
                  freeDuration: res.data.freeDuration,
                  freeUnit: res.data.freeUnit,
                  freeIncluded: res.data.freeIncluded === 1,
                  highestCharge: res.data.highestCharge,
                  onceDuration: res.data.onceDuration,
                  onceCharge: res.data.onceCharge,
                  onceUnit: res.data.onceUnit,
                  priceComment: res.data.priceComment,
                  freeLeaveMinutes: res.data.freeLeaveMinutes,
                },
                detailForm: {},
                timeRuleForm: {
                  priceUnitList: res.data.priceUnitList,
                },
                priceType: res.data.priceType,
              });
            }
          } else {
            reject(res.msg);
          }
        } catch (err) {
          reject(err);
        }
      });
    },
    async editRule(item) {
      this.$refs.chargingRules.baseForm.priceVersion = "临时";
      this.$refs.chargingRules.priceId = item.priceId;
      //避免编辑的内容影响到详情的内容
      let data = JSON.parse(JSON.stringify(item));
      Object.assign(this.$refs.chargingRules.baseForm, data.baseForm);
      Object.assign(this.$refs.chargingRules.chargeForm, data.chargeForm);
      if (data.priceType === "组合收费") {
        Object.assign(this.$refs.chargingRules.detailForm, data.detailForm);
      } else if (data.priceType === "按时长收费") {
        this.$refs.chargingRules.timeRuleForm = data.timeRuleForm;
      }
      this.$refs.chargingRules.show = true;
    },
    deleteRule(priceId) {
      this.$confirm("此操作将永久删除该规则, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          let res = await this.$http.delete(`/price/delete/${priceId}`);
          if (res.code === 0) {
            this.$message.success("删除成功");
            this.getTemporaryChargesList();
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch(() => {});
    },
    addRule() {
      this.$refs.chargingRules.baseForm.priceVersion = "临时";
      this.$refs.chargingRules.show = true;
    },
  },
};
</script>
<style lang="scss">
#temporaryCharges {
  .ruleDetail-wrap {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 20px;
    .table-container {
      width: 50%;
      box-sizing: border-box;
      .table-title {
        display: flex;
        justify-content: space-between;
        color: white !important;
        .operation {
        }
      }
    }
  }
}
</style>
